import { createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { CALL_API } from 'app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  createCommonThunks
} from '../commonSlice';

const endUsers = createSlice({
  name: 'endUsers',
  initialState: {
    ...commonInitialState,
    import: {
      isParsing: false,
      isImporting: false,
      parsedCsv: null,
    }
  },
  reducers: {
    ...commonReducers,
    parseRequest: (state) => { state.import.isParsing = true },
    parseSuccess: (state, action) => {
      state.import.isParsing = false;
      state.import.parsedCsv = action.payload;
    },
    parseError: (state) => {
      state.import.isParsing = false;
      state.import.parsedCsv = null;
    },
    importRequest: state => { state.import.isImporting = true },
    importSuccess: (state, action) => {
      state.import.isImporting = false;
      if (action.payload && action.payload.length) {
        state.entities.push(action.payload);
      }
    },
    importError: state => { state.import.isImporting = false },
  }
})

export const { actions } = endUsers;

export default endUsers.reducer

// Operations
const thunks = createCommonThunks('endUsers', '/end-user-fe');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;

// Custom operations
export const parseCSV = data => ({
  [CALL_API]: {
    types: [
      `endUsers/parseRequest`,
      `endUsers/parseSuccess`,
      `endUsers/parseError`,
    ],
    promiseFn: httpClient =>
      httpClient.post(`end-user-fe/import/parse/csv`, data),
  },
});

export const importUsers = ({
  userList,
  errorMessage,
  successMessage,
  onSuccess,
  onError,
}) => ({
  [CALL_API]: {
    types: [
      `endUsers/importRequest`,
      `endUsers/importSuccess`,
      `endUsers/importError`,
    ],
    promiseFn: httpClient =>
      httpClient.post(`end-user-fe/import/importUsers`, userList),
    errorMessage,
    successMessage,
    onSuccess,
    onError,
  },
});
