import { createSlice } from '@reduxjs/toolkit';
import { CALL_API } from 'app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  createCommonThunks,
} from '../commonSlice';

const initialState = {
  ...commonInitialState,
  organizations: {
    list: [],
    fetching: false,
  },
};

const accounts = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    ...commonReducers,
    fetchOrganizationsRequest: state => {
      state.organizations.fetching = true;
    },
    fetchOrganizationsSuccess: (state, action) => {
      state.organizations.fetching = false;
      state.organizations.list = action.payload;
    },
    fetchOrganizationsError: state => {
      state.organizations.fetching = false;
    },
  },
});

export const { actions } = accounts.actions;

export default accounts.reducer;

// Operations
const thunks = createCommonThunks('accounts', '/account');

export const { fetchAll, fetchOne, createEntity, deleteEntity } = thunks;

export const modifyAccount = ({
  account,
  errorMessage,
  successMessage,
  onSuccess,
  onError,
}) => ({
  [CALL_API]: {
    types: [
      `accounts/modifyRequest`,
      `accounts/modifySuccess`,
      `accounts/modifyError`,
    ],
    promiseFn: httpClient => httpClient.put(`account/modifyAccount`, account),
    errorMessage: errorMessage || 'There was a problem modifying the account',
    successMessage: successMessage || 'Account successfully modified',
    onSuccess: onSuccess || null,
    onError: onError || null,
  },
});

export const createAccount = ({
  account,
  errorMessage,
  successMessage,
  onSuccess,
  onError,
}) => ({
  [CALL_API]: {
    types: [
      `accounts/modifyRequest`,
      `accounts/modifySuccess`,
      `accounts/modifyError`,
    ],
    promiseFn: httpClient => httpClient.post(`account/createAccount`, account),
    errorMessage: errorMessage || 'There was a problem creating the account',
    successMessage: successMessage || 'Account successfully created',
    onSuccess: onSuccess || null,
    onError: onError || null,
  },
});

export const fetchOrganizations = () => ({
  [CALL_API]: {
    types: [
      `accounts/fetchOrganizationsRequest`,
      `accounts/fetchOrganizationsSuccess`,
      `accounts/fetchOrganizationsError`,
    ],
    promiseFn: httpClient => httpClient.get(`organization`),
  },
});
