import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// const activeLanguage = window.localStorage.getItem('lang') || 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      addPath: `/locales/add/{{ns}}?lang={{lng}}`,
      loadPath: `/locales/{{ns}}?lang={{lng}}`,
      crossDomain: true,
    },
    // lng: activeLanguage,
    fallbackLng: 'en',

    //! turn on locally to collate terms to translate
    // save missing keys
    saveMissing: true,
    // saveMissingTo: 'current',
    updateMissing: false,

    // have a translations namespace used everywhere in the app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys

    interpolation: {
      formatSeparator: ',',
    },

    // react options
    react: {
      bindI18nStore: 'added removed',
      bindI18n: 'languageChanged',
    },

    // debug: true,
  });
export default i18n;
