import React from 'react';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';
import { Container } from './Topbar.styled';
import topLogo from '../../../assets/images/top_logo.png';

const Topbar = ({ withMenu = true, onToggleDrawer }) => (
  <Container>
    <Link to="/">
      <img src={topLogo} style={{ width: 230 }} />
    </Link>
    {withMenu && <UserMenu onToggleDrawer={onToggleDrawer} />}
  </Container>
);

export default Topbar;
