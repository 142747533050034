import { createSlice } from '@reduxjs/toolkit'
import {
  commonInitialState,
  commonReducers,
  createCommonThunks
} from '../commonSlice';

const initialState = {
  ...commonInitialState
}

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: { ...commonReducers }
})

export const { actions } = messages;

export default messages.reducer

// Operations
const thunks = createCommonThunks('messages', '/message');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;
