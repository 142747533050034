import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1280px;
`;

export const StyledTabs = styled(Tabs)`
  margin: 10px 0 -5px;
`;

export const StyledTab = styled(Tab)`
  &:not(:first-child) {
    margin-left: -16px;
  }
  z-index: ${props => 9 - props.index};
`;

export const PageContent = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => (props.isWideView ? '0 11px 11px 11px' : '11px')};
  z-index: 99;
  position: relative;
  padding: 18px 36px 36px;
  margin-bottom: 5rem;
`;
