import React, { lazy, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.min.css';
import '../assets/css/react-toastify-custom.css';
import '../assets/css/material-ui-custom.css';
import 'typeface-barlow';
import { checkAuth } from '../features/auth/authSlice';

import theme from './theme';
import Routes from './Routes';
import Loader from '../components/shared/Loader';
import i18n from './i18n';

const Login = lazy(() => import('../components/pages/Login'));

const ToastClose = ({ closeToast }) => (
  <Button
    onClick={closeToast}
    style={{
      fontSize: '1.8rem',
      width: '2rem',
      color: 'rgba(255,255,255, 0.7)',
      marginTop: 8,
    }}
  >
    <MdClose />
  </Button>
);
ToastClose.propTypes = {
  closeToast: PropTypes.func,
};

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(checkAuth('You have been automatically logged out'));
  }, [dispatch]);

  useEffect(() => {
    if (auth.current && auth.current.languageCode) {
      const userLang = auth.current.languageCode.toLowerCase();
      i18n.changeLanguage(userLang);
    }
  }, [auth.current]);

  return (
    <StylesProvider injectFirst>
      {/* injectFirst prop allows styled components to inject after (override) MUI styles */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer position="bottom-right" closeButton={<ToastClose />} />
        {auth.isAuthenticated ? (
          <Router>
            <Routes />
          </Router>
        ) : (
          <Suspense fallback={<Loader />}>
            <Login />
          </Suspense>
        )}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
