import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import { Container, PageContent } from './Content.styled';

const Topbar = ({ children, page }) => {
  const isWideView = useMediaQuery('(min-width: 1100px)');

  return (
    <Container>
      <PageContent isWideView={isWideView}>{children}</PageContent>
    </Container>
  );
};

export default Topbar;
