import { createSlice } from '@reduxjs/toolkit';
import { CALL_API } from 'app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  createCommonThunks
} from '../commonSlice';

const initialState = {
  ...commonInitialState
}

const flaps = createSlice({
  name: 'flaps',
  initialState,
  reducers: { ...commonReducers }
})

export const { actions } = flaps.actions

export default flaps.reducer

// Operations
const thunks = createCommonThunks('flaps', '/flap');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;

// Custom operations
export const fetchByGateway = id => ({
  [CALL_API]: {
    types: [
      `flaps/fetchRequest`,
      `flaps/fetchAllSuccess`,
      `flaps/fetchError`
    ],
    promiseFn: httpClient =>
      httpClient.post(`gateway/${id}/flap/search`, { take: 100 }),
  },
});
