import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px auto;
  width: 90%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
