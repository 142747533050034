import { createMuiTheme } from '@material-ui/core';

// TODO: define multiple themes here the user can choose from settings

const orange = {
  main: '#FF951A',
  contrastText: '#1B2433',
};
const guava = {
  main: '#3a4a62',
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: guava,
    // using defaults here
    text: {
      primary: orange.main,
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
    // these are dark defaults
    background: {
      paper: '#182740',
      default: '#182740',
    },
    type: 'dark',
  },
  typography: {
    fontFamily: [
      'Rajdhani',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
  },
  shape: {
    borderRadius: '11px',
  },
  overrides: {
    MuiInput: {
      input: {
        padding: '0.5rem 1rem',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${orange.main}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#FF941A',
      },
    },
    MuiButton: {
      root: {
        minWidth: 108,
        height: 29,
        padding: 0,
      },
      text: {
        padding: '0 8px',
      },
    },
    MuiPaper: {
      root: {
        border: 'solid 1px #ff941a',
        backgroundImage: 'linear-gradient(to bottom, #17273f, #222e43)',
      },
      elevation1: {
        boxShadow: '4px 8px 20px 0 rgba(0, 0, 0, 0.26)',
      },
    },
    MuiTab: {
      root: {
        width: 205,
        height: 45,
        borderRadius: '11px 11px 0 0',
        opacity: 1,
        background: '#17273f',
        border: `1px solid ${orange.main}`,
        letterSpacing: 1.95,
      },
      wrapper: {
        fontSize: 20,
        fontWeight: 600,
        color: orange.main,
      },
      textColorInherit: {
        opacity: 1,
        '&:disabled': {
          opacity: 1,
          background: '#25364D',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1rem',
      },
    },
    // TABLE STYLES
    MuiTable: {
      root: {
        color: '#fff',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
        padding: '2px 12px',
        fontWeight: 200,
        fontSize: 20,
      },
      head: {
        fontSize: 20,
        fontWeight: 600,
        padding: '12px 12px',
      },
    },
    MuiTablePagination: {
      root: {
        padding: 100,
      },
    },
    MuiToolbar: {
      root: {
        padding: 0,
      },
    },
  },
});

export default theme;
