import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  useTheme,
  Menu,
  MenuItem,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import { modifyAccount } from 'features/accounts/accountsSlice';
import { useTranslation } from 'react-i18next';
import { MdMenu } from 'react-icons/md';

import { logout } from '../../../features/auth/authSlice';
import avatarIcon from '../../../assets/images/avatar_icon.png';
import pjson from '../../../../package.json';

const InfoPanel = styled.div`
  font-size: 0.8rem;
  width: 10rem;
  padding: 0.8rem 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  /* text-align: center; */
  /* colors for this section are just inversed */
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  background: ${props => props.theme.palette.text.primary};
  color: ${props => props.theme.palette.background.default};
  margin-top: -8px;

  div {
    margin: 0.2rem 0;
  }
  .app-version {
    font-size: 0.6rem;
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  padding: 0.5rem 0;
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

const Container = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  /* width: 85px; */
  font-size: 20px;
  font-weight: 600;

  :hover {
    cursor: pointer;
  }
`;

const StyledMenu = styled(Menu)`
  margin: 55px 0 0;
  .MuiPaper-root {
    padding: 0;
  }
`;

const languages = ['en', 'de'];

export default function UserMenu({ onToggleDrawer }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState();
  const currentUser = useSelector(state => state.auth.current);
  const history = useHistory();
  const isWideView = useMediaQuery('(min-width: 1100px)');

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () =>
    dispatch(
      logout({
        errorMessage: 'There was an error logging out',
        successMessage: 'Successfully logged out',
      })
    );

  const handleChangeLanguage = lang => () => {
    const account = {
      accountId: currentUser.id,
      name: currentUser.name,
      role: currentUser.role,
      email: currentUser.email,
      status: currentUser.status,
      organization___id: currentUser.organization___id,
      language___id: languages.indexOf(lang) + 1,
    };
    dispatch(
      modifyAccount({
        account,
        successMessage: t('Account language was successfully changed'),
        errorMessage: t('There was a problem changing the account language'),
        onSuccess: () => {
          i18n.changeLanguage(lang);
          window.localStorage.setItem('lang', lang);
          handleClose();
        },
      })
    );
  };

  const goToAccounts = () => {
    history.push('/accounts');
    handleClose();
  };

  return (
    <>
      <div style={{ width: 'auto' }}>
        <Container>
          <div
            onClick={handleClick}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img
              src={avatarIcon}
              style={{ width: 25, margin: '0 0.8rem' }}
              alt="User menu icon"
            />
            {isWideView ? currentUser.username : ''}
          </div>
          {!isWideView && (
            <IconButton
              onClick={onToggleDrawer}
              style={{ color: '#FF961A', marginRight: '1.3rem' }}
            >
              <MdMenu />
            </IconButton>
          )}
        </Container>
      </div>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <InfoPanel theme={theme}>
          <div>
            {t('Logged in as')}{' '}
            <strong>{currentUser && currentUser.username}</strong>
          </div>
          <div className="app-version">
            {pjson.name} v{pjson.version}
          </div>
        </InfoPanel>
        <SectionWrapper theme={theme}>
          <MenuItem
            onClick={handleChangeLanguage('en')}
            selected={i18n.language === 'en'}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={handleChangeLanguage('de')}
            selected={i18n.language === 'de'}
          >
            Deutsch
          </MenuItem>
        </SectionWrapper>
        <SectionWrapper theme={theme}>
          {currentUser.role !== 'MODERATOR' && (
            <MenuItem onClick={goToAccounts}>{t('Accounts')}</MenuItem>
          )}
          <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
        </SectionWrapper>
      </StyledMenu>
    </>
  );
}
