import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from '../components/layout';
import Loader from '../components/shared/Loader';

const NotFound = lazy(() => import('../components/pages/NotFound'));
const Locations = lazy(() => import('../components/pages/Locations'));
const Gateways = lazy(() => import('../components/pages/Gateways'));
const Tags = lazy(() => import('../components/pages/Tags'));
const Flaps = lazy(() => import('../components/pages/Flaps'));
const EndUsers = lazy(() => import('../components/pages/EndUsers'));
const Alarms = lazy(() => import('../components/pages/Alarms'));
const Messages = lazy(() => import('../components/pages/Messages'));
const Accounts = lazy(() => import('../components/pages/Accounts'));

const Routes = () => (
  <Router>
    <Layout>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Locations />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/locations"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Locations />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/gateways"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Gateways />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/tags"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Tags />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/flaps"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Flaps />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/end-users"
          render={() => (
            <Suspense fallback={<Loader />}>
              <EndUsers />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/alarms"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Alarms />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/messages"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Messages />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/accounts"
          render={() => (
            <Suspense fallback={<Loader />}>
              <Accounts />
            </Suspense>
          )}
        />
        <Route
          render={() => (
            <Suspense fallback={<Loader />}>
              <NotFound />
            </Suspense>
          )}
        />
      </Switch>
    </Layout>
  </Router>
);

export default Routes;
