import { createSlice } from '@reduxjs/toolkit'
import {
  commonInitialState,
  commonReducers,
  createCommonThunks
} from '../commonSlice';

const initialState = {
  ...commonInitialState
}

const messageTypes = createSlice({
  name: 'messageTypes',
  initialState,
  reducers: { ...commonReducers }
})

export const { actions } = messageTypes;

export default messageTypes.reducer

// Operations
const thunks = createCommonThunks('messageTypes', '/messageType');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;
