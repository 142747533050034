import { createSlice } from '@reduxjs/toolkit'
import { CALL_API } from '../../app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  // createCommonThunks
} from '../commonSlice';

const initialState = {
  fetching: false,
  current: null,
}

const grids = createSlice({
  name: 'grids',
  initialState,
  reducers: {
    fetchRequest(state, action) {
      state.fetching = true;
    },
    fetchSuccess(state, action) {
      state.fetching = false;
      state.current = action.payload;
    },
    fetchError(state, action) {
      state.fetching = false;
    },
  }
})

export const { actions } = grids;

export default grids.reducer

// Operations
// const thunks = createCommonThunks('gateways', '/gateway');

// export const {
//   fetchAll,
//   fetchOne,
//   createEntity,
//   modifyEntity,
//   deleteEntity,
// } = thunks;

export const fetchGatewayGrids = id => ({
  [CALL_API]: {
    types: [
      `grids/fetchRequest`,
      `grids/fetchSuccess`,
      `grids/fetchError`
    ],
    promiseFn: httpClient => httpClient.get(
      `gateway/${id}/gridView`
    ),
  },
});
