import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import httpClient from 'utilities/httpClient';

export const login = createAsyncThunk(
  'auth/login',
  async (
    { username, password, errorMessage, unauthorizedMessage, successMessage },
    thunkAPI
  ) => {
    try {
      const response = await httpClient.post('/login', { username, password });
      return response.data;
    } catch (err) {
      if (err?.response?.status === 401) toast.error(unauthorizedMessage);
      else toast.error(errorMessage);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async ({ unauthorizedMessage }, thunkAPI) => {
    const response = await httpClient.get('/check-auth');
    return response.data;
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async ({ errorMessage }, thunkAPI) => {
    const response = await httpClient.get('/logout');
  }
);

const initialState = {
  isAuthenticating: false,
  isAuthenticated: false,
  error: null,
  current: null,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.pending]: (state, action) => {
      state.isAuthenticating = true;
    },
    [login.rejected]: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.current = null;
    },
    [login.fulfilled]: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.current = action.payload;
    },
    [checkAuth.pending]: (state, action) => {
      state.isAuthenticating = true;
    },
    [checkAuth.rejected]: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.current = null;
    },
    [checkAuth.fulfilled]: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.current = action.payload;
    },
    [logout.fulfilled]: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = false;
      state.current = action.payload;
      state.current = null;
    },
  },
});

export const { actions } = auth.actions;

export default auth.reducer;
