import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'features/auth/authSlice';
import gatewaysReducer from 'features/gateways/gatewaysSlice';
import alarmsReducer from 'features/alarms/alarmsSlice';
import flapsReducer from 'features/flaps/flapsSlice';
import tagsReducer from 'features/tags/tagsSlice';
import endUsersReducer from 'features/endUsers/endUsersSlice';
import gridsReducer from 'features/grids/gridsSlice';
import messagesReducer from 'features/messages/messagesSlice';
import messageTypesReducer from 'features/messageTypes/messageTypesSlice';
import accountsReducer from 'features/accounts/accountsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  gateways: gatewaysReducer,
  alarms: alarmsReducer,
  flaps: flapsReducer,
  tags: tagsReducer,
  endUsers: endUsersReducer,
  grids: gridsReducer,
  messages: messagesReducer,
  messageTypes: messageTypesReducer,
  accounts: accountsReducer,
});

export default rootReducer;
