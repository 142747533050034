import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import store from './app/store';
import App from './app/App';
import './app/i18n';
// import 'typeface-rajdhani';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  // create an element if it doesn't exist (for testing)
  document.getElementById('root') || document.createElement('div')
);
