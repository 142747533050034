import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/shared/Loader';
import Topbar from './Topbar';
import Content from './Content';
import Menu from './Menu';

const Layout = ({ children, page }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const handleToggleDrawer = () => setIsDrawerOpen(prev => !prev);
  return (
    <div>
      <Suspense fallback={<Loader button />}>
        <Topbar onToggleDrawer={handleToggleDrawer} />
      </Suspense>
      <Suspense fallback={<Loader button />}>
        <Menu
          isDrawerOpen={isDrawerOpen}
          handleDrawerClose={handleToggleDrawer}
        />
      </Suspense>
      <Content page={page}>{children}</Content>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  goBack: PropTypes.func,
};

export default Layout;
