import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Drawer, MenuItem } from '@material-ui/core';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Container, StyledTabs, StyledTab } from './Content/Content.styled';

const StyledMenuItem = styled(MenuItem)`
  width: 180px;
  text-transform: uppercase;
  padding: 1rem 2rem;

  ${props =>
    props.isSelected
      ? `
    background: #fff;
    color: #0E2B4D;
    &:hover {
      color: #7B7D81;
    }
  `
      : ''}
`;

export default function Menu({ isDrawerOpen, handleDrawerClose }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [chosenTab, setChosenTab] = useState();
  const handleTabChange = (e, value) => history.push(value);
  const isWideView = useMediaQuery('(min-width: 1100px)');

  useEffect(() => {
    setChosenTab(location.pathname.replace('/', ''));
  }, [location.pathname]);

  return isWideView ? (
    <Container>
      <StyledTabs
        value={chosenTab}
        aria-label="Section navigation"
        onChange={handleTabChange}
      >
        <StyledTab label={t('Locations')} value="" index={0} />
        <StyledTab label={t('Tags')} value="tags" index={2} />
        <StyledTab label={t('Flaps')} value="flaps" index={3} />
        <StyledTab label={t('Users')} value="end-users" index={4} />
        <StyledTab label={t('Messages')} value="messages" index={5} />
      </StyledTabs>
    </Container>
  ) : (
    <Drawer
      open={isDrawerOpen}
      onClose={handleDrawerClose}
      style={{ border: 'none' }}
    >
      <div style={{ padding: '3rem 0' }}>
        <StyledMenuItem
          component={Link}
          to="/"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/'}
        >
          {t('Locations')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/tags"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/tags'}
        >
          {t('Tags')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/flaps"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/flaps'}
        >
          {t('Flaps')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/end-users"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/Users'}
        >
          {t('Users')}
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/messages"
          onClick={handleDrawerClose}
          isSelected={location.pathname === '/messages'}
        >
          {t('Messages')}
        </StyledMenuItem>
      </div>
    </Drawer>
  );
}
