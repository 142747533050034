import { createSlice } from '@reduxjs/toolkit';
import { CALL_API } from 'app/apiMiddleware';
import {
  commonInitialState,
  commonReducers,
  createCommonThunks
} from '../commonSlice';

const initialState = {
  ...commonInitialState,
  preview: {
    isFetching: false,
    isError: false,
    current: null,
  }
}

const tags = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    ...commonReducers,
    fetchPreviewRequest: state => {
      state.preview.isFetching = true;
      state.preview.isError = false;
      state.preview.current = null;
    },
    fetchPreviewSuccess: (state, action) => {
      state.preview.isFetching = false;
      state.preview.isError = false;
      state.preview.current = action.payload;
    },
    fetchPreviewError: state => {
      state.preview.isFetching = false;
      state.preview.isError = true;
      state.preview.current = null;
    },
  }
})

export const { actions } = tags;

export default tags.reducer

// Standard operations
const thunks = createCommonThunks('tags', '/tag');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;

// Custom operations
export const fetchByGateway = id => ({
  [CALL_API]: {
    types: [
      `tags/fetchRequest`,
      `tags/fetchAllSuccess`,
      `tags/fetchError`
    ],
    promiseFn: httpClient =>
      httpClient.post(`gateway/${id}/tag/search`, { take: 100 }),
  },
});

//! I think react-query could really help in these situations rather than redux
export const getTagImage = id => ({
  [CALL_API]: {
    types: [
      `tags/fetchPreviewRequest`,
      `tags/fetchPreviewSuccess`,
      `tags/fetchPreviewError`
    ],
    promiseFn: httpClient =>
      httpClient.get(`tag/image/${id}`),
  },
});
