import { createSlice } from '@reduxjs/toolkit'
import {
  commonInitialState,
  commonReducers,
  createCommonThunks
} from '../commonSlice';

const initialState = {
  ...commonInitialState
}

const gateways = createSlice({
  name: 'gateways',
  initialState,
  reducers: { ...commonReducers }
})

export const { actions } = gateways;

export default gateways.reducer

// Operations
const thunks = createCommonThunks('gateways', '/gateway');

export const {
  fetchAll,
  fetchOne,
  createEntity,
  modifyEntity,
  deleteEntity,
} = thunks;
